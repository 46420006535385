import FormPage from '@/components/FormPage'
import { createGetParams, createPostParams } from '@/rest'
import {
  formatPhoneNumber,
  getRm,
  getNumeralFromRm,
  stringGen,
} from '@/utils'

const formTitle = 'Dealers'

export default {
  extends: FormPage,
  data () {
    return {
      name: null,
      street1: null,
      street2: null,
      city: null,
      postalCode: null,
      state: null,
      breadcrumbs: [
        {
          text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
        },
        {
          text: 'Corporate Kits', disabled: false, to: '/operation/corporate_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      paymentDetails: {
        fields: {
          ecash: {
            name: 'E-recharge balance',
          },
          msisdn: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'Mobile number',
            props: {
              existing: true,
              noPrepaid: true,
              hint: 'Black mobile number',
              label: 'Enter mobile number',
              presistentHint: true,
              required: true,
              rules: [val => !!val || 'Black mobile number required!'],
              searchNetwork: true,
            },
          },
          plan: {
            name: 'Black plan',
          },
          amountDue: {
            name: 'Amount due',
          },
          payment: {
            component: () => import(/* webpackChunkName: "amountField" */ '@/components/AmountField'),
            name: 'Payment',
            props: {
              label: 'Enter amount to pay',
              required: true,
              rules: [],
            },
          },
        },
        subTitle: 'Account information & payment',
        title: 'Payment details',
      },
      submission: {
        fields: {
          msisdn: {
            name: 'Mobile number',
          },
          plan: {
            name: 'Plan',
          },
          amountDue: {
            name: 'Amount due',
          },
          payment: {
            emphasize: true,
            name: 'Payment',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        paymentDetails: this.paymentDetails,
        submission: this.submission,
      }
    },
    ecash: function () {
      return this.paymentDetails.fields.ecash
    },
    payment: function () {
      return this.paymentDetails.fields.payment.value
    },
  },
  mounted: function () {
    this.paymentDetails.fields.payment.props.rules.push(
      () => this.validatePayment(),
    )
    const params = createGetParams({
      erecharge: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      this.ecash.error = null
      this.ecash.value = getRm(response.data.erecharge)
    }.bind(this)).catch(error => {
      this.ecash.error = error.message
    })
  },
  watch: {
    'paymentDetails.fields.msisdn.value': function (val) {
      this.submission.fields.msisdn.value = formatPhoneNumber(val)
      if (!val) return

      setTimeout(() => {
        if (!this.paymentDetails.fields.msisdn.error &&
          ((val.length === 11 && val.startsWith('011')) || (val.length === 10 && !val.startsWith('011')))) {
          const params = createGetParams({
            target: this.paymentDetails.fields.msisdn.value,
          })
          this.$rest.get('getAmountDue.php', params)
            .then(response => {
              const amountDue = response.data.amountDue === null ? null : response.data.amountDue
              this.name = response.data.name
              this.street1 = response.data.street1 ? response.data.street1 : null
              this.street2 = response.data.street2 ? response.data.street2 : null
              this.city = response.data.city ? response.data.city : null
              this.postalCode = response.data.postalCode ? response.data.postalCode : null
              this.state = response.data.state ? response.data.state : null
              this.paymentDetails.fields.amountDue.value = amountDue === null ? null : getRm(amountDue)
              this.paymentDetails.fields.plan.value = response.data.plan.replace(/_/g, ' ')
            }).catch(() => {
            // ignore
            })
        } else {
          this.paymentDetails.fields.plan.value = null
          this.paymentDetails.fields.amountDue.value = null
        }
      }, 1000)
    },
    'paymentDetails.fields.payment.value': function (val) {
      this.submission.fields.payment.value = getRm(val)

      if (Number(val) > getNumeralFromRm(this.ecash.value)) {
        this.paymentDetails.fields.payment.validate && this.paymentDetails.fields.payment.validate(true)
      } else {
        this.submission.fields.payment.value = getRm(val)
        this.paymentDetails.fields.payment.validate && this.paymentDetails.fields.payment.validate()
      }
    },
    'paymentDetails.fields.amountDue.value': function (val) {
      this.submission.fields.amountDue.value = val
    },
    'paymentDetails.fields.plan.value': function (val) {
      this.submission.fields.plan.value = val
    },
  },
  methods: {
    validatePayment: function () {
      if (Number(this.payment) <= 0) return 'Payment amount required!'
      if (Number(this.payment) > getNumeralFromRm(this.ecash.value)) return 'Insufficient e-recharge!'
      return true
    },
    // Override
    async submit () {
      const params = createPostParams({
        action: 'payBill',
        payload: {
          target: this.paymentDetails.fields.msisdn.value,
          amount: Number(this.paymentDetails.fields.payment.value).toFixed(2),
          outstanding: getNumeralFromRm(this.paymentDetails.fields.amountDue.value).toFixed(2),
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to make a Black bill payment was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
          // this.downloadReceipt()
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
    async downloadReceipt () {
      let invoice = 'B-' + stringGen(9)
      invoice = invoice.toUpperCase()

      const payload = {
        msisdn: this.paymentDetails.fields.msisdn.value,
        amount: this.paymentDetails.fields.payment.value,
        invoice: invoice,
        name: this.name,
        street1: this.street1,
        street2: this.street2,
        city: this.city,
        postalCode: this.postalCode,
        state: this.state,
      }

      const data = process.env.NODE_ENV === 'production' ? { payload: payload }
        : { impersonate: 223992, payload: payload }
      try {
        const response = await this.$rest({
          url: process.env.NODE_ENV === 'production' ? location.protocol + '//' + location.hostname + '/api/pdf/billPaymentReceipt.php'
            : 'http://onesys.onexox.my/pdf/billPaymentReceipt.php',
          method: 'POST',
          responseType: 'blob',
          data: data,
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', 'black_bill_payment_resit.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (e) {}
    },
  },
}
